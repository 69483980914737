import React from "react";
import styled from "styled-components";

interface IcoProps {
  label: string | React.ReactNode;
  sublabel?: string | React.ReactNode;
  section?: string | React.ReactNode;
}

interface WrapperProps {
  section?: string | React.ReactNode;
}

const IcoBox: React.FC<IcoProps> = ({ children, label, sublabel, section }) => {
  return (
    <Wrapper section={section}>
      <IcoWrapper>{children}</IcoWrapper>
      <Label>{label}</Label>
      {sublabel && <SubLabel>{sublabel}</SubLabel>}
    </Wrapper>
  );
};

export default IcoBox;

const Wrapper = styled.div.attrs<WrapperProps>(({ section }) => ({
  className: `w-1/2 ${section === "two" ? "tablet:w-1/2 tablet:pr-20" : "tablet:w-1/3"} mb-10 tablet:mb-16 `,
}))<WrapperProps>``;
const Label = styled.p.attrs({
  className: "font-bold text-white mt-4 text-lg ",
})``;
const SubLabel = styled.p.attrs({ className: "text-sm text-gray4" })``;
const IcoWrapper = styled.div.attrs({
  className: "w-16 h-16 flex items-end justify-start",
})``;

import React from "react";
import styled from "styled-components";
import { Hero, HeroHeadline } from "../components/hero";
import MainSection from "../components/main-section/main-section";
import SectionGallery from "../components/section-gallery";
import InfoSection from "../sections/benefits/info-section";
import ExpertiseSection from "../sections/benefits/expertise-section";
import WorkSection from "../sections/benefits/work-section";
import { JobsSearchTile, TileNavigation } from "../components/tile-navigation";
import Layout from "../components/layout";
import { graphql, PageProps } from "gatsby";
import { JobOffersCountInterface } from "../graphql/jobs";
import { BenefitsPageContentInterface } from "../graphql/benefits-page";
import { transformHeroData } from "../utilities/pimcore-data-transformer";
import Scrollspy, { ScrollspyProps } from "../components/scrollspy/scrollspy";

interface BenefitsPageDataInterface {
  pimcore: {
    content: BenefitsPageContentInterface;
    jobOffersCount: JobOffersCountInterface;
  };
}

const labels = [
  "Enabling you to thrive",
  "A great start",
  "A place to thrive",
  "A world of expertise",
  "Rewarding you",
  "Always growing",
  "Time to socialise",
  "Flexibility at work",
  "Valuing diversity",
];

const BenefitsPage: React.FC<PageProps<BenefitsPageDataInterface>> = ({ data }) => {
  const content = data.pimcore.content;
  return (
    <Layout
      navCurrentItem="benefits"
      title={content.metaTitle}
      description={content.metaDescription}
      canonical={content.metaCanonical}
      openGraph={{ title: content.metaOgTitle, description: content.metaOgTitle, image: content.metaOgImage }}
      schemaOrg={content.metaSchema}
    >
      <Header>
        <Hero {...transformHeroData(content)}>
          <HeroHeadline dangerouslySetInnerHTML={{ __html: content.heroTitle || "" }} />
        </Hero>
      </Header>
      <Main>
        <MainSection
          headline={content.mainSectionHeadline}
          subHeadline={content.mainSectionSubheadline}
          content={content.mainSectionContent}
          background={content.mainSectionBackground}
          backgroundMobile={content?.mainSectionBackgroundMobile}
        >
          <SectionGallery images={content.gallery} />
        </MainSection>
        <InfoSection content={content.info} />
        <ExpertiseSection content={content.expertise} />
        <WorkSection content={content.work} />
        <TileNavigation top={"team"} bottom={"office"}>
          <JobsSearchTile jobsCount={data.pimcore.jobOffersCount.totalCount} />
        </TileNavigation>
      </Main>
      <Scrollspy labels={labels} gap={9} />
    </Layout>
  );
};

const Header = styled.header.attrs({ className: "h-screen" })``;

const Main = styled.main.attrs({ className: "x-background overflow-x-hidden -mt-24" })``;

export const query = graphql`
  query {
    pimcore {
      ...benefitsPageData
      ...jobOffersCount
    }
  }
`;

export default BenefitsPage;

import React from "react";
import styled from "styled-components";
import QuoteElement from "../../components/quote-element/quote-element";
import { Headline2, Paragraph } from "../../components/typography";
import { theme } from "../../styles/styles";
import { PageElement } from "../../graphql/page-common";
import { getElementImage, getElementText } from "../../utilities/pimcore-data-transformer";
import { PimcoreImage } from "../../components/pimcore-image";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollAnimationDesktop, returnRefArray } from "../../utilities/animation-helper";

interface WorkSectionProps {
  content: Array<PageElement>;
}

interface WrapperProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const WorkSection: React.FC<WorkSectionProps> = ({ content }) => {
  const matchMobile = useMatchMedia("(max-width: 768px)");
  const tl1 = React.useRef<GSAPTimeline>();
  const tl2 = React.useRef<GSAPTimeline>();
  const firstWrapper = React.useRef<HTMLDivElement>(null);
  const secondWrapper = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const firstChildrenIndexSection = [2, 1, 0, 3];
    const firstChildrenIndexSectionMobile = [0, 1, 3];
    const firstIndexSection = matchMobile ? firstChildrenIndexSectionMobile : firstChildrenIndexSection;

    const secondChildrenIndexSection = [1, 0, 2, 3, 4, 5];
    const secondChildrenIndexSectionMobile = [0, 1, 2, 5];
    const secondIndexSection = matchMobile ? secondChildrenIndexSectionMobile : secondChildrenIndexSection;

    tl1.current = onScrollAnimationDesktop(
      firstWrapper.current,
      returnRefArray(firstWrapper.current, firstIndexSection),
      matchMobile
    );
    tl2.current = onScrollAnimationDesktop(
      secondWrapper.current,
      returnRefArray(secondWrapper.current, secondIndexSection),
      matchMobile
    );

    return () => {
      tl1?.current?.kill();
      tl2?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <>
      <FirstSection ref={firstWrapper}>
        <MediaCell1>
          <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[0]) }} />
        </MediaCell1>
        <MediaCell4>
          <QuoteElement
            message="What I love is the flexibility. In terms of how I work and the way I work, it’s very flexible.  And the working environment is designed to support this."
            name="Terri"
            position="Head of UX/UI"
            modifier="left-4 bottom-4 tablet:left-auto tablet:bottom-48 tablet:right-[22rem]"
          />
          <PimcoreImage image={getElementImage(content[1])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell4>
        <MediaCell2>
          <PimcoreImage image={getElementImage(content[2])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell2>
        <MediaCell3>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[3]) }} />
        </MediaCell3>
      </FirstSection>

      <SecondSection ref={secondWrapper}>
        <MediaCell5>
          <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[4]) }} />
        </MediaCell5>
        <MediaCell6>
          <QuoteElement
            message="One thing that makes Miele X special is its inclusiveness.  We’re all from different backgrounds, countries and areas of expertise. It’s inspiring."
            name="Kapil"
            position="Senior Product Manager"
            modifier="left-4 bottom-4 tablet:left-auto tablet:bottom-[26rem] tablet:right-72"
          />
          <PimcoreImage image={getElementImage(content[5])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell6>
        <MediaCell7>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[6]) }} />
        </MediaCell7>
        <MediaCell8>
          <PimcoreImage image={getElementImage(content[7])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell8>
        <MediaCell9>
          <PimcoreImage image={getElementImage(content[8])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell9>
      </SecondSection>
    </>
  );
};

export default WorkSection;

const Wrapper = styled.div.attrs<WrapperProps>({
  className: "tablet:grid tablet:grid-cols-12 tablet:gap-4 px-5 tablet:grid-flow-col scrollspy",
})<WrapperProps>`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(2, auto);
  }
`;

const FirstSection = styled(Wrapper).attrs({ className: "" })``;
const SecondSection = styled(Wrapper).attrs({ className: "mb-28	tablet:mb-36" })``;

const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-1 tablet:row-span-1 -mt-8 tablet:mt-28",
})``;
const MediaCell2 = styled.div.attrs({
  className: "hidden tablet:block tablet:col-start-1 tablet:col-span-6 tablet:row-start-1 tablet:row-span-2 ",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-2 tablet:row-span-2 mb-14 tablet:mb-0 tablet:pr-4",
})``;
const MediaCell4 = styled.div.attrs({
  className:
    "tablet:col-start-3 tablet:col-span-4 tablet:row-start-3 tablet:row-span-1 mb-8 tablet:mb-0 tablet:mb-36 relative z-10",
})``;
const MediaCell5 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-4 tablet:row-span-1",
})``;
const MediaCell6 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-6 tablet:row-start-4 tablet:row-span-2 mb-8 tablet:mb-0 relative",
})``;
const MediaCell7 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-5 tablet:row-start-5 tablet:row-span-2 mb-8 tablet:mb-0 tablet:pr-24",
})``;
const MediaCell8 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-4 tablet:row-start-6 tablet:row-span-2 hidden tablet:block",
})``;
const MediaCell9 = styled.div.attrs({
  className:
    "tablet:col-start-2 tablet:col-span-5 tablet:row-start-6 tablet:row-span-3 tablet:mt-24 hidden tablet:block",
})``;


import React from "react";
import styled from "styled-components";
import { ImageLeftWrapper, ImageRightWrapper } from "../../components/images/images";
import { Headline2, Paragraph } from "../../components/typography";
import { theme } from "../../styles/styles";
import IcoBox from "../../components/icon/IcoBox";
import QuoteElement from "../../components/quote-element/quote-element";
import { PageElement } from "../../graphql/page-common";
import { getElementFact, getElementImage, getElementText } from "../../utilities/pimcore-data-transformer";
import { PimcoreImage } from "../../components/pimcore-image";
import FactsBox from "../../components/facts-box";
import { useMatchMedia } from "../../hooks/use-match-media";
import { Features } from "./info-section";
import {
  BikeIcon,
  CalendarWithClockIcon,
  CoffeIcon,
  CashIcon,
  EventIcon,
  ShoeIcon,
  SportIcon,
  StaffIcon,
  TeamIcon,
  TramIcon,
  UmbrellaIcon,
} from "../../images/icons/Icons";
import { onScrollAnimationDesktop, returnRefArray } from "../../utilities/animation-helper";

const firstFeatures: Array<Features> = [
  {
    label: "christmas and performance bonuses",
    icon: CashIcon,
  },
  {
    label: "25 days paid annual leave",
    icon: CalendarWithClockIcon,
  },
  {
    label: (
      <>
        sports <br />
        allowance
      </>
    ),
    icon: ShoeIcon,
  },
  {
    label: (
      <>
        NS business <br />
        card
      </>
    ),
    icon: TramIcon,
  },
  {
    label: (
      <>
        swapfiets
        <br />
        bike
      </>
    ),
    icon: BikeIcon,
  },
  {
    label: "pension and retirement plan",
    icon: UmbrellaIcon,
    sublabel: (
      <>
        (100% covered by <br />
        Miele X )
      </>
    ),
  },
];

const secondFeatures: Array<Features> = [
  {
    label: (
      <>
        team
        <br />
        activities
      </>
    ),
    icon: TeamIcon,
  },
  {
    label: (
      <>
        staff
        <br />
        parties
      </>
    ),
    icon: StaffIcon,
  },
  {
    label: (
      <>
        sports
        <br />
        teams
      </>
    ),
    icon: SportIcon,
  },
  {
    label: (
      <>
        cultural
        <br />
        events
      </>
    ),
    icon: EventIcon,
  },
  {
    label: (
      <>
        coffee
        <br />
        meet-ups
      </>
    ),
    icon: CoffeIcon,
  },
];

interface ExpertiseSectionProps {
  content: Array<PageElement>;
}

interface ParagraphProps {
  padding?: string;
}

interface WrapperProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const ExpertiseSection: React.FC<ExpertiseSectionProps> = ({ content }) => {
  const tl1 = React.useRef<GSAPTimeline>();
  const tl2 = React.useRef<GSAPTimeline>();
  const tl3 = React.useRef<GSAPTimeline>();
  const tl4 = React.useRef<GSAPTimeline>();
  const firstWrapper = React.useRef<HTMLDivElement>(null);
  const secondWrapper = React.useRef<HTMLDivElement>(null);
  const thirdWrapper = React.useRef<HTMLDivElement>(null);
  const fourthWrapper = React.useRef<HTMLDivElement>(null);
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    const firstChildrenIndexSection = [0, 1, 3, 2, 4];
    const firstChildrenIndexSectionMobile = [0, 2, 3];
    const firstIndexSection = matchMobile ? firstChildrenIndexSectionMobile : firstChildrenIndexSection;

    const secondChildrenIndexSection = [1, 0, 2, 3];
    const secondChildrenIndexSectionMobile = [0, 1, 2];
    const secondIndexSection = matchMobile ? secondChildrenIndexSectionMobile : secondChildrenIndexSection;

    const thirdChildrenIndexSection = [0, 1, 3, 2, 4];
    const thirdChildrenIndexSectionMobile = [0, 2, 3];
    const thirdIndexSection = matchMobile ? thirdChildrenIndexSectionMobile : thirdChildrenIndexSection;

    const fourthChildrenIndexSection = [0, 1, 2, 4, 3];
    const fourthChildrenIndexSectionMobile = [0, 1, 2];
    const fourthIndexSection = matchMobile ? fourthChildrenIndexSectionMobile : fourthChildrenIndexSection;

    tl1.current = onScrollAnimationDesktop(
      firstWrapper.current,
      returnRefArray(firstWrapper.current, firstIndexSection),
      matchMobile
    );
    tl2.current = onScrollAnimationDesktop(
      secondWrapper.current,
      returnRefArray(secondWrapper.current, secondIndexSection, [2]),
      matchMobile
    );
    tl3.current = onScrollAnimationDesktop(
      thirdWrapper.current,
      returnRefArray(thirdWrapper.current, thirdIndexSection),
      matchMobile
    );
    tl4.current = onScrollAnimationDesktop(
      fourthWrapper.current,
      returnRefArray(fourthWrapper.current, fourthIndexSection, [2]),
      matchMobile
    );

    return () => {
      tl1?.current?.kill();
      tl2?.current?.kill();
      tl3?.current?.kill();
      tl4?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <>
      <FirstSectionWrapper ref={firstWrapper}>
        <MediaCell1>
          <HeadlineWrapper>
            <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[0]) }} />
          </HeadlineWrapper>
        </MediaCell1>
        <MediaCell2>
          <PimcoreImage image={getElementImage(content[1])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell2>
        <MediaCell4>
          <QuoteElement
            message="For me, diversity and inclusion define Miele X, bringing varied perspectives and innovative problem-solving approaches to the forefront."
            name="Ekaterina"
            position="Consent Manager"
            modifier="left-4 bottom-4 tablet:left-auto tablet:bottom-[32rem] tablet:right-56"
          />
          <PimcoreImage image={getElementImage(content[2])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell4>
        <MediaCell3>
          <ParagraphWrapper>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[3]) }} />
          </ParagraphWrapper>
          <FactsBox facts={[getElementFact(content[4])]} />
        </MediaCell3>
        <MediaCell5>
          <QuoteElement
            message="Respecting diversity doesn’t only make your work a happier place, but it also improves productivity and business processes."
            name="Soundarya"
            position="QA Engineer"
            modifier="hidden tablet:flex tablet:bottom-36 tablet:left-96"
            left={true}
          />
          <ImageLeftWrapper>
            <PimcoreImage image={getElementImage(content[5])} modifier="w-full rounded-lg" withAspectRatio />
          </ImageLeftWrapper>
        </MediaCell5>
      </FirstSectionWrapper>
      <SecondSectionWrapper ref={secondWrapper}>
        <MediaCell6>
          <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[6]) }} />
        </MediaCell6>
        <MediaCell7>
          <PimcoreImage image={getElementImage(content[7])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell7>
        <MediaCell8>
          <ParagraphWrapper padding="tablet:pr-4">
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[8]) }} />
          </ParagraphWrapper>
          <IcoWraper>
            {firstFeatures.map((feature, index) => (
              <IcoBox
                key={`benefit1-${index}`}
                label={feature.label}
                sublabel={feature.sublabel ? feature.sublabel : ""}
                section={feature.section ? feature.section : ""}
              >
                <IcoImg src={feature.icon.src} alt={feature.icon.alt} />
              </IcoBox>
            ))}
          </IcoWraper>
        </MediaCell8>
        <MediaCell9>
          <ImageRightWrapper>
            <PimcoreImage image={getElementImage(content[9])} modifier="w-full rounded-lg" withAspectRatio />
          </ImageRightWrapper>
        </MediaCell9>
      </SecondSectionWrapper>
      <ThirdSectionWrapper ref={thirdWrapper}>
        <MediaCell10>
          <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[10]) }} />
        </MediaCell10>
        <MediaCell11>
          <PimcoreImage image={getElementImage(content[11])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell11>
        <MediaCell13>
          <QuoteElement
            message="There’s a very well thought-through development programme. Giving you all you need to grow and thrive at Miele X."
            name="Qichun"
            position="Data Visualisation Expert"
            modifier="left-4 bottom-4 tablet:bottom-[32rem] tablet:left-80"
            left={true}
          />
          <PimcoreImage image={getElementImage(content[12])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell13>
        <MediaCell12>
          <ParagraphWrapper padding="tablet:pr-20">
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[13]) }} />
          </ParagraphWrapper>
        </MediaCell12>

        <MediaCell14>
          <PimcoreImage image={getElementImage(content[14])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell14>
      </ThirdSectionWrapper>
      <FourthSectionWrapper ref={fourthWrapper}>
        <MediaCell15>
          <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[15]) }} />
        </MediaCell15>
        <MediaCell16>
          <PimcoreImage image={getElementImage(content[16])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell16>
        <MediaCell17>
          <ParagraphWrapper padding="tablet:pr-12">
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[17]) }} />
          </ParagraphWrapper>
          <IcoWraper>
            {secondFeatures.map((feature, index) => (
              <IcoBox
                key={`benefit2-${index}`}
                label={feature.label}
                sublabel={feature.sublabel ? feature.sublabel : ""}
                section={feature.section ? feature.section : ""}
              >
                <IcoImg src={feature.icon.src} alt={feature.icon.alt} />
              </IcoBox>
            ))}
          </IcoWraper>
        </MediaCell17>
        <MediaCell18>
          <PimcoreImage image={getElementImage(content[18])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell18>
        <MediaCell19>
          <ImageRightWrapper>
            <PimcoreImage image={getElementImage(content[19])} modifier="w-full rounded-lg" withAspectRatio />
          </ImageRightWrapper>
        </MediaCell19>
      </FourthSectionWrapper>
    </>
  );
};

export default ExpertiseSection;

const Wrapper = styled.div.attrs<WrapperProps>({
  className: "tablet:grid tablet:grid-cols-12 tablet:gap-4 px-5 tablet:grid-flow-col	",
})<WrapperProps>``;
const FirstSectionWrapper = styled(Wrapper).attrs({
  className: "tablet:mb-36 scrollspy",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(5, auto);
  }
`;
const IcoImg = styled.img.attrs({ className: "min-w-full h-full" })``;
const SecondSectionWrapper = styled(Wrapper).attrs({
  className: "tablet:mb-36 scrollspy",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(2, auto);
  }
`;
const ThirdSectionWrapper = styled(Wrapper).attrs({
  className: " scrollspy",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(3, auto);
  }
`;
const FourthSectionWrapper = styled(Wrapper).attrs({
  className: "mb-14 tablet:mb-36 scrollspy",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(10, auto);
  }
`;

const ParagraphWrapper = styled.div.attrs<ParagraphProps>(({ padding }) => ({
  className: `${padding && padding} tablet:mb-14 mb-8`,
}))<ParagraphProps>``;

const IcoWraper = styled.div.attrs({
  className: "flex flex-wrap justify-star",
})``;

const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-5 tablet:row-start-1 tablet:row-span-1",
})``;
const MediaCell2 = styled.div.attrs({
  className: "hidden tablet:block tablet:col-start-2 tablet:col-span-5 tablet:row-start-1 tablet:row-span-3  ",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-5 tablet:row-start-2 tablet:row-span-4 mb-14 tablet:mb-0 tablet:pr-16",
})``;
const MediaCell4 = styled.div.attrs({
  className: "tablet:row-start-4 tablet:row-span-2 tablet:col-start-4 tablet:col-span-3 relative mb-8 tablet:mb-0",
})``;
const MediaCell5 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-5 tablet:row-start-4 mt-28 hidden tablet:block relative",
})``;
const HeadlineWrapper = styled.div.attrs({ className: "tablet:mt-20" })``;

const MediaCell6 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-1 tablet:row-span-1 tablet:mt-10",
})``;
const MediaCell7 = styled.div.attrs({
  className: "tablet:col-start-1 tablet:col-span-6 tablet:row-start-1 tablet:row-span-2 mb-8 tablet:mb-0",
})``;
const MediaCell8 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-2 tablet:row-span-3",
})``;
const MediaCell9 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-5 tablet:row-start-3 tablet:row-span-1 hidden tablet:block",
})``;
const MediaCell10 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-5 tablet:row-start-1 tablet:row-span-1 pr-16 tablet:pr-0 tablet:mt-20",
})``;
const MediaCell11 = styled.div.attrs({
  className: "hidden tablet:block tablet:col-start-8 tablet:col-span-4 tablet:row-start-1 tablet:row-span-2 ",
})``;
const MediaCell12 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-5 tablet:row-start-2 tablet:row-span-3 mb-14 tablet:mb-0",
})``;

const MediaCell13 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-4 tablet:row-start-3 tablet:row-span-2 relative mb-8 tablet:mb-0",
})``;
const MediaCell14 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-5 tablet:row-start-3 tablet:row-span-2  mt-20 hidden tablet:block",
})``;
const MediaCell15 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-1 tablet:row-span-1",
})``;
const MediaCell16 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-5 tablet:row-start-1 tablet:row-span-3 tablet:mt-40 mb-8 tablet:mb-0",
})``;
const MediaCell17 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-6 tablet:row-start-2 tablet:row-span-1 tablet:pr-16",
})``;
const MediaCell18 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-5 tablet:row-start-3 tablet:row-span-4 hidden tablet:block",
})``;
const MediaCell19 = styled.div.attrs({
  className: "tablet:col-start-3 tablet:col-span-4 tablet:row-start-4 tablet:row-span-1 hidden tablet:block",
})``;
